import { DateTime } from 'luxon';
import clock from '../../common/clock/clock';
import { Action } from '../actions';
import {
  NOP_WINDOW_STATUS,
  UPDATE_NOP_GRID_DATE,
  UPDATE_NOP_GRID_SELECTION,
  EfaDay,
  Period,
  ADD_NOP_CHANGESETS,
  NopChangeset,
  ADD_NOP_CHANGESET,
  GridSelectionMetaData,
} from './actions';
import horizonReducer from '../../data/horizonReducer';
import allocateEfaDayCorrectDate from '../../common/dates/efaDate';

export type HorizonWithMetadata = [NopHorizon, HorizonMetadata];

export type NetOpenPosition = {
  date: EfaDay;
  periods: Period[];
};

export type StatusInfo = {
  receivedAt: DateTime | null;
};

export type NopHorizon = Record<string, NetOpenPosition>;

export type HorizonMetadata = {
  orphans: {
    tier1: NopChangeset[];
    tier2: NopChangeset[];
    tier3: NopChangeset[];
  };
  incompleteDays: NopHorizon;
};

export type GridSelection = GridSelectionMetaData[];

export type NopState = {
  nop: NopHorizon;
  horizonMetadata: HorizonMetadata;
  status: StatusInfo;
  horizonWindowOn: boolean;
  gridDate: string;
  gridSelection: GridSelection;
};

const initialState: NopState = <NopState>{
  nop: {},
  horizonWindowOn: true,
  gridDate: allocateEfaDayCorrectDate(),
  horizonMetadata: <HorizonMetadata>{
    orphans: { tier1: [], tier2: [], tier3: [] },
    incompleteDays: {},
  },
  gridSelection: [
    {
      name: 'All',
      value: true,
    },
    {
      name: 'Forecasts',
      value: true,
    },
    {
      name: 'Total NOP',
      value: true,
    },
    {
      name: 'EDF NOP',
      value: true,
    },
    {
      name: 'WBB NOP',
      value: true,
    },
    {
      name: 'Batteries NOP',
      value: true,
    },
    {
      name: 'Gas Peaker NOP',
      value: true,
    },
    {
      name: 'CONS NOP',
      value: true,
    },
    {
      name: 'Batteries',
      value: true,
    },
    {
      name: 'Gas Peaker',
      value: true,
    },
    {
      name: 'WBB',
      value: true,
    },
    {
      name: 'Nuclear',
      value: true,
    },
    {
      name: 'Embed_Gen',
      value: true,
    },
    {
      name: 'Customer_Vol',
      value: true,
    },
    {
      name: 'Trades',
      value: true,
    },
    {
      name: 'WBB Trades',
      value: true,
    },
    {
      name: 'Indices',
      value: true,
    },
    {
      name: 'DA Hourly Auction - EPEX',
      value: false,
    },
    {
      name: 'DA Hourly Auction - N2EX',
      value: false,
    },
    {
      name: 'MIP - EPEX',
      value: true,
    },
    {
      name: 'WAP - EPEX',
      value: true,
    },
    {
      name: 'IMRP',
      value: false,
    },
  ],
};

export const netOpenPosition = (
  state: NopState = initialState,
  action: Action,
) => {
  switch (action.type) {
    case ADD_NOP_CHANGESETS: {
      const { payload }: { payload: NopChangeset[] } = action;
      const reduce = horizonReducer(state.nop, state.horizonMetadata, payload);

      return {
        ...state,
        nop: reduce[0],
        horizonMetadata: reduce[1],
        status: { receivedAt: clock().now().toLuxon() },
      };
    }
    case ADD_NOP_CHANGESET: {
      const { payload }: { payload: NopChangeset } = action;
      const reduce = horizonReducer(state.nop, state.horizonMetadata, [
        payload,
      ]);
      return {
        ...state,
        nop: reduce[0],
        horizonMetadata: reduce[1],
        status: { receivedAt: clock().now().toLuxon() },
      };
    }
    case NOP_WINDOW_STATUS: {
      const { payload } = action;

      return {
        ...state,
        horizonWindowOn: payload,
      };
    }
    case UPDATE_NOP_GRID_DATE: {
      const { payload } = action;

      return {
        ...state,
        gridDate: payload,
      };
    }
    case UPDATE_NOP_GRID_SELECTION: {
      const { payload } = action;

      const items = payload;

      return {
        ...state,
        gridSelection: items,
      };
    }
    default:
      return state;
  }
};
