import {
  DefaultAligneTimeGetter,
  DefaultCalendarTimeGetter,
  DefaultEfaTimeGetter,
  productConfig,
  ProductTimeGetter,
} from '@edfenergy/shift-desk-efa-calendar';

type Holder = {
  instance: ProductTimeGetter | null;
  get: () => ProductTimeGetter;
};

const holder: Holder = {
  instance: null,
  get: () => {
    if (!holder.instance) {
      holder.instance = new ProductTimeGetter(
        new DefaultEfaTimeGetter(new DefaultCalendarTimeGetter(productConfig)),
        new DefaultCalendarTimeGetter(productConfig),
        new DefaultAligneTimeGetter(productConfig),
      );
    }
    return holder.instance;
  },
};

export default () => holder.get();
