import React from 'react';
import { EfaDate } from '@edfenergy/shift-desk-efa-calendar';
import Unit from '../Unit';
import Config from '../Config';
import Output from '../Output';
import BrDate from '../BrDate';
import { useBalancingReserveSelector } from '../../store/selectors/balancingReserveSelector';
import { Header, ScreenContainer } from './styles';
import Submit from '../Submit';
import { useUnitValidationSelector } from '../../store/selectors/unitValidationSelector';

const BalancingReserveScreen = () => {
  document.title = 'WBB Balancing Reserve - EDF Shift Trading';

  const balancingReserveState = useBalancingReserveSelector();

  const efaDate = EfaDate.fromFormat(balancingReserveState.date);

  const unitValidation = useUnitValidationSelector();

  return (
    <ScreenContainer>
      <Header>
        <h1>WBB Balancing Reserve</h1>
        <BrDate efaDate={efaDate} />
      </Header>
      {Object.values(balancingReserveState.units).map((unit) => (
        <Unit
          key={unit.id}
          unit={unit}
          efaDate={efaDate}
          validation={unitValidation[unit.id]}
        />
      ))}
      <Config />
      <Output />
      <Submit />
    </ScreenContainer>
  );
};

export default BalancingReserveScreen;
