import { Auth } from '@edfenergy/res-agentui-sso-auth';
import apiGatewayClientFactory from 'aws-api-gateway-client';
import { AxiosError } from 'axios';

const connectionTimeout = 10000;
const requestTimeout = 600000;

type HttpVerb = 'GET' | 'POST' | 'DELETE' | 'UPDATE';

type Response<SR, ER> = SuccessResponse<SR> | ErrorResponse<ER>;

type SuccessResponse<SR> = {
  data: SR;
  error: null;
};

type ErrorResponse<ER> = {
  data: null;
  error: {
    status: number;
    data: ER | string;
  };
};

export const authenticatedAwsQuery =
  <SuccessResponseType, ErrorResponseType, InputType>(
    url: string,
    method: HttpVerb,
  ) =>
  async (
    data: InputType,
  ): Promise<Response<SuccessResponseType, ErrorResponseType>> => {
    let credentials;

    try {
      credentials = await Auth.currentCredentials();
    } catch (error) {
      return {
        data: null,
        error: {
          status: 401,
          data: 'Could not authorise with AWS.',
        },
      };
    }

    console.log(
      'process.env.REACT_APP_WMO_TRADING_API_ENDPOINT',
      process.env.REACT_APP_WMO_TRADING_API_ENDPOINT,
    );

    try {
      const config = {
        invokeUrl: process.env.REACT_APP_WMO_TRADING_API_ENDPOINT,
        region: 'eu-west-1',
        accessKey: credentials.accessKeyId,
        secretKey: credentials.secretAccessKey,
        sessionToken: credentials.sessionToken,
        timeout: connectionTimeout,
      };

      const client = apiGatewayClientFactory.newClient(config);

      const pathParams = {};
      const additionalParams = { timeout: requestTimeout };

      const relativeUrl = `/${url}`;

      const result = await client.invokeApi(
        pathParams,
        relativeUrl,
        method,
        additionalParams,
        data as unknown as string,
      );

      return {
        data: result.data as SuccessResponseType,
        error: null,
      };
    } catch (error) {
      const axiosError = error as AxiosError;
      return {
        data: null,
        error: {
          status: axiosError.response?.status || 500,
          data: axiosError.response?.data as ErrorResponseType,
        },
      };
    }
  };

export default {};
