import styled from 'styled-components';

export const ScreenContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow-x: auto;
  padding: 50px;
  background: #e5eff8;
  color: #3c3d42;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
