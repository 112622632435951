import { Action } from '../actions';

export const SET_TRADER_ID = 'SET_TRADER_ID';
export const SET_GROUPS = 'SET_GROUPS';

export const setTraderId = (payload: string): Action => ({
  type: SET_TRADER_ID,
  payload,
});

export const setGroups = (groups: string[]): Action => ({
  type: SET_GROUPS,
  payload: groups,
});
