import React from 'react';
import { useDispatch } from 'react-redux';
import { UNIT_BMUS, updateBmu } from '../../store/balancingReserveSlice';
import { useBalancingReserveSelector } from '../../store/selectors/balancingReserveSelector';
import { Select } from '../SpRange/styles';

const SELECT_UNIT_BMUS = ['no-select', ...UNIT_BMUS];

const UnitSelector = ({ id }: { id: string }) => {
  const dispatch = useDispatch();
  const unitState = useBalancingReserveSelector().units[id];

  const handleOnChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newBmu = e.target.value;
    dispatch(
      updateBmu({ unitId: id, newBmu: newBmu === 'no-select' ? null : newBmu }),
    );
  };

  return (
    <label htmlFor="unitSelection">
      Unit:
      <Select
        id="unitSelection"
        value={unitState.bmu || 'no-select'}
        onChange={handleOnChange}
      >
        {SELECT_UNIT_BMUS.map((bmu) => (
          <option key={bmu} value={bmu}>
            {bmu === 'no-select' ? 'Select...' : bmu}
          </option>
        ))}
      </Select>
    </label>
  );
};

export default UnitSelector;
