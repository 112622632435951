import styled from 'styled-components';

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: 4px solid #bcc2dd;
  margin-bottom: 2em;
  th {
    background: #bcc2dd;
    padding: 0.5em;
    text-align: left;
  }
  td {
    padding: 0.5em;
  }
`;

export const Th = styled.th``;
