import { Action } from '../actions';
import {} from './reducer';

export const TOGGLE_GLOBAL_MUTE = 'TOGGLE_GLOBAL_MUTE';
export const UNMUTE = 'UNMUTE';

export const toggleMute = (): Action => ({
  type: TOGGLE_GLOBAL_MUTE,
  payload: null,
});

export const unmute = (): Action => ({
  type: UNMUTE,
  payload: null,
});
