import React from 'react';
import { useDispatch } from 'react-redux';
import { EfaDate } from '@edfenergy/shift-desk-efa-calendar';
import UnitSelect from '../UnitSelect';
import { Unit as UnitData, addRange } from '../../store/balancingReserveSlice';
import SpRange from '../SpRange';
import { UnitContainer, Validation, ValidationItem } from './styles';
import {
  UnitValidation,
  isRangeIdInUnitValidation,
} from '../../store/selectors/unitValidationSelector';

const Unit = ({
  unit,
  efaDate,
  validation,
}: {
  unit: UnitData;
  efaDate: EfaDate;
  validation: UnitValidation;
}) => {
  const dispatch = useDispatch();

  const handleOnClick = () => {
    dispatch(addRange({ unitId: unit.id }));
  };

  return (
    <UnitContainer>
      <h2>{unit.name}</h2>
      <p>
        <UnitSelect id={unit.id} />
      </p>
      {validation.length > 0 && (
        <Validation>
          {validation.map((item) => (
            <ValidationItem key={item.message}>
              ⚠️ {item.message}
            </ValidationItem>
          ))}
        </Validation>
      )}
      {Object.values(unit.spRanges).map((range) => (
        <SpRange
          key={range.id}
          unitId={unit.id}
          range={range}
          efaDate={efaDate}
          hasValidationError={isRangeIdInUnitValidation(validation, range.id)}
        />
      ))}
      <p>
        <button type="button" onClick={handleOnClick}>
          +
        </button>
      </p>
    </UnitContainer>
  );
};

export default Unit;
