import styled from 'styled-components';

export const RangeContainer = styled.div<{ hasError: boolean }>`
  margin-bottom: 2em;
  border: 4px dashed ${(props) => (!props.hasError ? '#a1a4b3' : '#c14f4f')};
  padding: 1em;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Select = styled.select`
  padding: 5px;
`;

export const Input = styled.input`
  padding: 5px;
  width: 80px;
`;

export const SliderContainer = styled.div`
  margin: 1em 0;
`;

export const FieldSpan = styled.span`
  margin-right: 1.5em;
`;
