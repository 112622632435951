import styled from 'styled-components';
import { LoadingIndicators } from '@edfenergy/shift-desk-wallace';

export const SubmitButton = styled.button`
  padding: 0.5em;
  font-size: 1.5em;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
`;

export const LoadingIndicator = styled(LoadingIndicators)`
  margin-right: 1.5em;
`;
