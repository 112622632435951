import React, { memo } from 'react';
import { AuthContainer } from '@edfenergy/res-agentui-sso-auth';
import { useDispatch } from 'react-redux';

import config from '../common/config';

import { setGroups } from '../store/user/actions';

type AuthProps = {
  children: React.ReactNode;
};

const Auth = ({ children }: AuthProps) => {
  const dispatch = useDispatch();

  return (
    <AuthContainer
      autoLogin
      amplifyConfig={config}
      onSignIn={(session) => {
        dispatch(
          setGroups(session.session.getAccessToken().payload['cognito:groups']),
        );
      }}
      renderer={({ errorMessage, defaultRenderer }) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {errorMessage ? (
            <div style={{ width: 500, height: 200 }}>
              <p>
                You don&apos;t appear to have access to the active directory
                needed to use the Axiom Platform. Please contact the
                <a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}>
                  WMS Software Engineering team
                </a>
                to rectify this.
              </p>
            </div>
          ) : (
            <div
              style={{
                width: 500,
                height: 200,
                textAlign: 'center',
                marginTop: '30px',
              }}
            >
              {defaultRenderer()}
            </div>
          )}
        </div>
      )}
    >
      {children}
    </AuthContainer>
  );
};

export default memo(Auth);
