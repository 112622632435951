import { Action } from '../actions';
import { TOGGLE_GLOBAL_MUTE, UNMUTE } from './actions';

export type AudioPlayerState = {
  isGloballyMuted: boolean;
};

const initialState: AudioPlayerState = {
  isGloballyMuted: false,
};

const audioPlayerState = (
  state: AudioPlayerState = initialState,
  action: Action,
): AudioPlayerState => {
  switch (action.type) {
    case TOGGLE_GLOBAL_MUTE: {
      return {
        ...state,
        isGloballyMuted: !state.isGloballyMuted,
      };
    }
    case UNMUTE: {
      return {
        ...state,
        isGloballyMuted: false,
      };
    }
    default:
      return state;
  }
};

export default audioPlayerState;
