import React from 'react';
import { useDispatch } from 'react-redux';
import { EfaDate, Product } from '@edfenergy/shift-desk-efa-calendar';
import {
  SpRange as SpRangeData,
  RangeType,
  editRange,
  removeRange,
} from '../../store/balancingReserveSlice';
import SpSlider from '../SpSlider';
import { SpSelection } from '../SpSlider/SpSlider';
import {
  ButtonContainer,
  FieldSpan,
  Input,
  RangeContainer,
  Select,
  SliderContainer,
} from './styles';

const SpRange = ({
  unitId,
  range,
  efaDate,
  hasValidationError,
}: {
  unitId: string;
  range: SpRangeData;
  efaDate: EfaDate;
  hasValidationError: boolean;
}) => {
  const dispatch = useDispatch();

  const handleCloseOnClick = () => {
    dispatch(removeRange({ unitId, rangeId: range.id }));
  };

  const handleSliderOnChange = (event: SpSelection) => {
    dispatch(
      editRange({
        unitId,
        rangeId: range.id,
        newRangeData: {
          start: event.start.toId(),
          end: event.end.toId(),
        },
      }),
    );
  };

  const handleFieldOnChange = <T,>(key: string, value: T): void => {
    dispatch(
      editRange({
        unitId,
        rangeId: range.id,
        newRangeData: {
          [key]: value,
        },
      }),
    );
  };

  return (
    <RangeContainer hasError={hasValidationError}>
      <ButtonContainer>
        <button type="button" title="Remove range" onClick={handleCloseOnClick}>
          x
        </button>
      </ButtonContainer>
      <SliderContainer>
        <SpSlider
          spSelection={{
            start: Product.fromId(range.start),
            end: Product.fromId(range.end),
          }}
          forDate={efaDate}
          showEfaBlocks
          onChange={handleSliderOnChange}
        />
      </SliderContainer>
      <p>
        <FieldSpan>
          Type:
          <Select
            id="type"
            value={range.type}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              handleFieldOnChange<RangeType>(
                'type',
                e.target.value as RangeType,
              );
            }}
          >
            <option key="POSITIVE" value="POSITIVE">
              PBR
            </option>
            <option key="NEGATIVE" value="NEGATIVE">
              NBR
            </option>
          </Select>
        </FieldSpan>
        <FieldSpan>
          Volume (MW):
          <Input
            type="number"
            value={range.volume === null ? '' : range.volume}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleFieldOnChange<number | null>(
                'volume',
                e.target.value ? parseInt(e.target.value, 10) : null,
              );
            }}
          />
        </FieldSpan>
        <FieldSpan>
          Total revenue (£):
          <Input
            id="field-total-revenue"
            type="number"
            value={range.totalRevenue === null ? '' : range.totalRevenue}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleFieldOnChange<number | null>(
                'totalRevenue',
                e.target.value ? parseFloat(e.target.value) : null,
              );
            }}
          />
        </FieldSpan>
        <FieldSpan>
          Price (£):
          <Input
            id="field-price"
            type="number"
            value={range.price === null ? '' : range.price}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleFieldOnChange<number | null>(
                'price',
                e.target.value ? parseFloat(e.target.value) : null,
              );
            }}
          />
        </FieldSpan>
      </p>
    </RangeContainer>
  );
};

export default SpRange;
