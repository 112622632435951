import React from 'react';
import { Button, Toast } from '@edfenergy/shift-desk-wallace';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { ButtonContainer, LoadingIndicator } from './styles';
import { useOutputSelector } from '../../store/selectors/outputSelector';
import { submitBalancingReserve } from '../../data/submitBalancingReserve';
import {
  setAsSubmitting,
  submissionFailure,
  submissionSuccess,
} from '../../store/balancingReserveSlice';
import { useBalancingReserveSelector } from '../../store/selectors/balancingReserveSelector';

const Submit = () => {
  const dispatch = useDispatch();
  const balancingReserveState = useBalancingReserveSelector();
  const output = useOutputSelector();

  const handleOnSubmit = async () => {
    dispatch(setAsSubmitting());
    const result = await submitBalancingReserve(
      output.map((outputRow) => ({
        unit: outputRow.bmu,
        type: outputRow.type === 'POSITIVE' ? 'PBR' : 'NBR',
        auctionDate: outputRow.auctionDate.minus(1).toFormat(),
        deliveryStartUtc: outputRow.startDateTimeUtc,
        deliveryEndUtc: outputRow.endDateTimeUtc,
        volume: outputRow.volume,
        price: outputRow.price,
        familyName: outputRow.basketFamily,
      })),
    );

    if (result.error === null) {
      dispatch(submissionSuccess());
      toast(
        <Toast messageType="success" id="balancing-reserve-submit-success">
          Successfully submitted to auction.
        </Toast>,
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
          closeButton: false,
        },
      );
    } else {
      dispatch(submissionFailure());
      toast(
        <Toast messageType="error" id="balancing-reserve-submit-error">
          There was a problem submitting to the auction [{result.error.data}]
        </Toast>,
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
          closeButton: false,
        },
      );
    }
  };

  return (
    <ButtonContainer>
      {balancingReserveState.isSubmitting && (
        <LoadingIndicator
          type="circle"
          size="md"
          colour="blue"
          position="right"
        />
      )}
      <Button
        label="Submit"
        type="button"
        size="lg"
        onClick={handleOnSubmit}
        disabled={output.length === 0 || balancingReserveState.isSubmitting}
      />
    </ButtonContainer>
  );
};

export default Submit;
