import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';

const StyledToastContainer = styled(ToastContainer).attrs({
  className: 'toast-container',
  toastClassName: 'toast',
  bodyClassName: 'body',
  progressClassName: 'progress',
})`
  width: 400px;
  .toast {
    border-radius: 0;
    box-shadow: none;
    padding: 0;
  }
  .body {
    padding: 0;
    margin: 0;
    min-height: var(--toastify-toast-min-height);
  }
  .body > div:last-child {
    height: 100%;
  }
`;

export default StyledToastContainer;
