/* eslint-disable no-continue */
import { EfaDate, Product } from '@edfenergy/shift-desk-efa-calendar';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { balancingReserveSelector } from './balancingReserveSelector';
import { RangeType } from '../balancingReserveSlice';
import { unitValidationSelector } from './unitValidationSelector';
import productMapper from '../../../common/products/mapper';
import timeGetter from '../../../common/products/timeGetter';

export type BalancingReserveOutput = OutputRow[];

export type OutputRow = {
  id: string;
  bmu: string;
  basketFamily: string;
  auctionDate: EfaDate;
  sp: number;
  startDateTimeUtc: string;
  endDateTimeUtc: string;
  volume: number;
  price: number;
  type: RangeType;
};

export const outputSelector = createSelector(
  [balancingReserveSelector, unitValidationSelector],
  (balancingReserveState, validation): BalancingReserveOutput => {
    const output: BalancingReserveOutput = [];
    for (const [unitId, unitData] of Object.entries(
      balancingReserveState.units,
    )) {
      if (validation[unitId].length > 0) {
        continue;
      }
      if (unitData.bmu === null) {
        continue;
      }
      for (const [rangeId, rangeData] of Object.entries(unitData.spRanges)) {
        if (rangeData.volume === null || rangeData.price === null) {
          continue;
        }

        const productStart = Product.fromId(rangeData.start);
        const productEnd = Product.fromId(rangeData.end);

        const products = productMapper().getHalfHourProductsBetweenTimes(
          timeGetter().getCalendarTime(productStart).start,
          timeGetter().getCalendarTime(productEnd).end,
        );

        products.forEach((product) => {
          output.push({
            id: `${unitId}-${rangeId}-${product.getName()}`,
            bmu: unitData.bmu as string,
            basketFamily: `${balancingReserveState.startTimestamp}-${rangeData.id}`,
            auctionDate: EfaDate.fromFormat(balancingReserveState.date),
            sp: parseInt(product.getName(), 10),
            startDateTimeUtc: timeGetter()
              .getCalendarTime(product)
              .start.toLuxon()
              .toUTC()
              .toISO(),
            endDateTimeUtc: timeGetter()
              .getCalendarTime(product)
              .end.toLuxon()
              .toUTC()
              .toISO(),
            volume: rangeData.volume as number,
            price: rangeData.price as number,
            type: rangeData.type,
          });
        });
      }
    }
    return output;
  },
);

export const useOutputSelector = () => useSelector(outputSelector);
