import { authenticatedAwsQuery } from './fetching';

export type BalancingReserveSubmission = {
  unit: string;
  type: 'PBR' | 'NBR';
  auctionDate: string;
  deliveryStartUtc: string;
  deliveryEndUtc: string;
  volume: number;
  price: number;
  familyName: string;
};

type BalancingReserveSubmissionSuccess = null;
type BalancingReserveSubmissionError = string;

export const submitBalancingReserve = async (
  data: BalancingReserveSubmission[],
) => {
  const balancingReserveSubmitQuery = authenticatedAwsQuery<
    BalancingReserveSubmissionSuccess,
    BalancingReserveSubmissionError,
    BalancingReserveSubmission[]
  >('BalancingReserve/submit', 'POST');

  return balancingReserveSubmitQuery(data);
};

export default {};
