import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { Product } from '@edfenergy/shift-desk-efa-calendar';
import { balancingReserveSelector } from './balancingReserveSelector';
import { RangeType, Unit, forEachUnits } from '../balancingReserveSlice';
import timeGetter from '../../../common/products/timeGetter';

export type UnitValidationItem = {
  message: string;
  affectedRangeIds: string[];
};
export type UnitValidation = UnitValidationItem[];
export type ValidationForAllUnits = Record<string, UnitValidation>;

type Range = {
  start: number;
  end: number;
  type: RangeType;
  index: number;
  id: string;
};

export const isRangeIdInUnitValidation = (
  validation: UnitValidation,
  rangeId: string,
): boolean =>
  validation.some((item) => item.affectedRangeIds.includes(rangeId));

const doRangesHaveFewerThanOnePeriodBetweenThem = (
  rangeA: Range,
  rangeB: Range,
): boolean => {
  if (rangeA.end === rangeB.start - 1 || rangeB.end === rangeA.start - 1) {
    return true;
  }
  return false;
};

const doRangesImperfectlyOverlap = (rangeA: Range, rangeB: Range): boolean => {
  if (rangeA.start === rangeB.start && rangeA.end === rangeB.end) return false; // we allow for exact overlaps

  if (rangeA.start <= rangeB.start && rangeB.start <= rangeA.end) return true; // b starts in a
  if (rangeA.start <= rangeB.end && rangeB.end <= rangeA.end) return true; // b ends in a
  if (rangeB.start < rangeA.start && rangeA.end < rangeB.end) return true; // a in b

  return false;
};

const compareRanges = (
  rangesA: Range[],
  rangesB: Range[],
  comparisonFunction: (rangeA: Range, rangeB: Range) => boolean,
): [Range, Range][] => {
  const matching: [Range, Range][] = [];

  rangesA.forEach((rangeA) => {
    rangesB.forEach((rangeB) => {
      if (comparisonFunction(rangeA, rangeB)) {
        matching.push([rangeA, rangeB]);
      }
    });
  });

  return matching;
};

const retrieveRanges = (unit: Unit): Range[] =>
  Object.values(unit.spRanges).map((range, index) => ({
    start: timeGetter()
      .getCalendarTime(Product.fromId(range.start))
      .start.toMillis(),
    end:
      timeGetter().getCalendarTime(Product.fromId(range.end)).end.toMillis() -
      1,
    type: range.type,
    index,
    id: range.id,
  }));

const filterRanges = (ranges: Range[], type: RangeType): Range[] =>
  ranges.filter((range) => range.type === type);

export const unitValidationSelector = createSelector(
  [balancingReserveSelector],
  (balancingReserveState): ValidationForAllUnits => {
    const validationForAllUnits: ValidationForAllUnits = {};
    forEachUnits(balancingReserveState.units, (unit) => {
      const ranges = retrieveRanges(unit);
      const positiveRanges = filterRanges(ranges, 'POSITIVE');
      const negativeRanges = filterRanges(ranges, 'NEGATIVE');

      const imperfectlyOverlappingRanges = compareRanges(
        positiveRanges,
        negativeRanges,
        doRangesImperfectlyOverlap,
      );

      const rangesWithFewerThanOnePeriodBetweenThem = compareRanges(
        positiveRanges,
        negativeRanges,
        doRangesHaveFewerThanOnePeriodBetweenThem,
      );

      const validationForUnit: UnitValidationItem[] = [];

      imperfectlyOverlappingRanges.forEach(([rangeA, rangeB]) => {
        validationForUnit.push({
          message: `${rangeA.type} range (${
            rangeA.index + 1
          }) imperfectly overlaps with ${rangeB.type} range (${
            rangeB.index + 1
          })`,
          affectedRangeIds: [rangeA.id, rangeB.id],
        });
      });

      rangesWithFewerThanOnePeriodBetweenThem.forEach(([rangeA, rangeB]) => {
        validationForUnit.push({
          message: `${rangeA.type} range (${
            rangeA.index + 1
          }) has less than one settlement period between it and ${
            rangeB.type
          } range (${rangeB.index + 1})`,
          affectedRangeIds: [rangeA.id, rangeB.id],
        });
      });

      validationForAllUnits[unit.id] = validationForUnit;
    });
    return validationForAllUnits;
  },
);

export const useUnitValidationSelector = () =>
  useSelector(unitValidationSelector);
