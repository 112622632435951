import React, { memo } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import 'react-toastify/dist/ReactToastify.css';

// import Dashboard from './container/Dashboard';
// import Tier3Nop from './container/Tier3Nop';
import ClockDisplay from './components/ClockDisplay';
import Auth from './Auth';
// import MainAxiomScreen from './MainAxiomScreen';
import BalancingReserveScreen from './balancing-reserve/components/BalancingReserveScreen';
import ToastContainer from './components/ToastContainer';

// const Tier3Route = () => (
//   <MainAxiomScreen>
//     <Tier3Nop />
//   </MainAxiomScreen>
// );

// const DashboardRoute = () => (
//   <MainAxiomScreen>
//     <Dashboard />
//   </MainAxiomScreen>
// );

const BalancingReserveRoute = () => <BalancingReserveScreen />;

const App = () => (
  <>
    <ClockDisplay />
    <Auth>
      <Router>
        <Switch>
          <Route path="/" component={BalancingReserveRoute} />
          {/* <Route path="/tier3/:owner/:portfolio" component={Tier3Route} />
          <Route path="/" component={DashboardRoute} /> */}
        </Switch>
      </Router>
      <ToastContainer />
    </Auth>
  </>
);

export default memo(App);
