import React, { useMemo } from 'react';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import {
  CalendarDate,
  DomainDate,
  EfaDate,
  Product,
  ProductType,
} from '@edfenergy/shift-desk-efa-calendar';
import './styles.css';
import productMapper from '../../../common/products/mapper';
import { Select } from '../SpRange/styles';
import timeGetter from '../../../common/products/timeGetter';

export type SpSelection = {
  start: Product;
  end: Product;
};

const getIndexForSp = (sp: Product, periods: Product[]): number =>
  periods.findIndex((period) => period.toId() === sp.toId());

const convertRangeToSpSelection = (
  range: [number, number],
  periods: Product[],
): SpSelection => ({
  start: periods[range[0]],
  end: periods[range[1] - 1],
});

const convertSpSelectionToRange = (
  spSelection: SpSelection,
  periods: Product[],
): [number, number] => [
  getIndexForSp(spSelection.start, periods),
  getIndexForSp(spSelection.end, periods) + 1,
];

const getPeriods = (forDate: DomainDate): Product[] => {
  if (forDate instanceof EfaDate) {
    return productMapper().getHalfHourProductsForEfaDate(forDate);
  }
  if (forDate instanceof CalendarDate) {
    return productMapper().getHalfHourProductsForCalendarDate(forDate);
  }
  throw new Error('forDate must either be an EfaDate or a CalendarDate');
};

const getProducts = (forDate: DomainDate): Product[] => {
  if (forDate instanceof EfaDate) {
    return productMapper().getProductsForEfaDate(forDate);
  }
  if (forDate instanceof CalendarDate) {
    return productMapper().getProductsForCalendarDate(forDate);
  }
  throw new Error('forDate must either be an EfaDate or a CalendarDate');
};

const getEfaBlocks = (
  forDate: DomainDate,
): { name: string; value: string }[] => [
  { name: 'Select...', value: 'none' },
  ...getProducts(forDate)
    .filter((product) => product.getType() !== ProductType.HalfHour)
    .sort((a, b) =>
      a.getType().toString().localeCompare(b.getType().toString()),
    )
    .map((product) => ({ name: product.getName(), value: product.toId() })),
];

const getSelectedEfaBlock = (spSelection: SpSelection): string => {
  const matchingBlock = productMapper().getProductByStartEndTime(
    timeGetter().getCalendarTime(spSelection.start).start,
    timeGetter().getCalendarTime(spSelection.end).end,
  );
  if (matchingBlock === null) {
    return 'none';
  }
  return matchingBlock.toId();
};

const SpSlider = ({
  spSelection,
  forDate,
  showEfaBlocks = true,
  onChange = () => {},
}: {
  spSelection: SpSelection;
  forDate: DomainDate;
  showEfaBlocks: boolean;
  onChange: (event: SpSelection) => void;
}) => {
  const periods = useMemo(() => getPeriods(forDate), [forDate.toFormat()]);

  const efaBlocks = showEfaBlocks
    ? useMemo(() => getEfaBlocks(forDate), [forDate.toFormat()])
    : [];

  const selectedEfaBlock = showEfaBlocks
    ? useMemo(() => getSelectedEfaBlock(spSelection), [spSelection])
    : 'none';

  const incomingRange = convertSpSelectionToRange(spSelection, periods);

  const sliderHandleOnChange = (range: [number, number]) => {
    onChange(convertRangeToSpSelection(range, periods));
  };

  const efaBlockHandleOnChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    if (event.target.value === 'none') {
      return;
    }
    const product = Product.fromId(event.target.value);
    const periods = productMapper().getHalfHourProductsContainedWithin(product);
    onChange({
      start: periods[0],
      end: periods[periods.length - 1],
    });
  };

  return (
    <>
      {showEfaBlocks && (
        <p>
          EFA block:
          <Select onChange={efaBlockHandleOnChange} value={selectedEfaBlock}>
            {efaBlocks.map((block) => (
              <option value={block.value} key={block.value}>
                {block.name}
              </option>
            ))}
          </Select>
        </p>
      )}
      <RangeSlider
        className="sp-slider"
        min={0}
        max={periods.length}
        step={1}
        value={incomingRange}
        onInput={sliderHandleOnChange}
      />
      <p>
        Selected SPs: {spSelection.start.getName()} -{' '}
        {spSelection.end.getName()}
      </p>
    </>
  );
};

export default SpSlider;
