import styled from 'styled-components';

export const UnitContainer = styled.div`
  background: #bcc2dd;
  color: #282b35;
  margin-bottom: 2em;
  padding: 1em;
`;

export const Validation = styled.ul`
  list-style: none;
  padding-left: 0;
  color: #b90000;
  font-size: 0.85em;
`;

export const ValidationItem = styled.li``;
