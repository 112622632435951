import {
  DefaultProductMapper,
  ProductMapper,
  productConfig,
} from '@edfenergy/shift-desk-efa-calendar';
import timeGetter from './timeGetter';

type Holder = {
  instance: ProductMapper | null;
  get: () => ProductMapper;
};

const holder: Holder = {
  instance: null,
  get: () => {
    if (!holder.instance) {
      holder.instance = new DefaultProductMapper(productConfig, timeGetter());
    }
    return holder.instance;
  },
};

export default () => holder.get();
