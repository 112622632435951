import { Action } from '../actions';
import { SET_GROUPS, SET_TRADER_ID } from './actions';

export type UserState = {
  traderID: string;
  groups: string[];
};

const initialState = <UserState>{
  traderID: '',
  groups: [],
};

export const user = (state: UserState = initialState, action: Action) => {
  switch (action.type) {
    case SET_TRADER_ID: {
      const { payload } = action;

      return { ...state, traderID: payload };
    }
    case SET_GROUPS: {
      const { payload } = action;

      return { ...state, groups: payload };
    }
    default:
      return state;
  }
};
