import {
  CalendarDate,
  Product,
  ProductType,
} from '@edfenergy/shift-desk-efa-calendar';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { memoize, omit, uniqueId } from 'lodash';
import { DateTime } from 'luxon';
import mapper from '../../common/products/mapper';
import timeGetter from '../../common/products/timeGetter';
import clock from '../../common/clock/clock';

export const UNIT_BMUS = ['T_WBURB-1', 'T_WBURB-2', 'T_WBURB-3'];

export type SpRanges = Record<string, SpRange>;

export type RangeType = 'POSITIVE' | 'NEGATIVE';

export type SpRange = {
  id: string;
  start: string;
  end: string;
  type: RangeType;
  price: number | null;
  volume: number | null;
  totalRevenue: number | null;
};

export type Units = Record<string, Unit>;

export type Unit = {
  id: string;
  name: string;
  bmu: string | null;
  spRanges: SpRanges;
};

export type BalancingReserveState = {
  startTimestamp: number;
  date: string;
  units: Units;
  isSubmitting: boolean;
};

const initialDate = (): DateTime => {
  const now = clock().now().toLuxon();
  const eightFifteen = now.set({ hour: 8, minute: 15, second: 0 });
  if (now.toMillis() > eightFifteen.toMillis()) {
    return now.plus({ day: 2 });
  }
  return now.plus({ day: 1 });
};

const initialState: BalancingReserveState = {
  startTimestamp: DateTime.now().toUnixInteger(),
  date: initialDate().toISODate(),
  units: {
    '0': { id: '0', name: 'Hedge unit', bmu: null, spRanges: {} },
    '1': { id: '1', name: 'Prompt unit', bmu: null, spRanges: {} },
  },
  isSubmitting: false,
};

type AddRangeActionPayload = {
  unitId: string;
};

type EditRangeActionPayload = {
  unitId: string;
  rangeId: string;
  newRangeData: Partial<SpRange>;
};

type RemoveRangeActionPayload = {
  unitId: string;
  rangeId: string;
};

type UpdateBmuActionPayload = {
  unitId: string;
  newBmu: string | null;
};

type UpdateDateActionPayload = {
  newDate: string;
};

type Pricing = {
  volume: number | null;
  totalRevenue: number | null;
  price: number | null;
};

const countPeriodsInRangeMemoResolver = (start: string, end: string) =>
  `${start}-${end}`;

const countPeriodsInRange = memoize((start: string, end: string): number => {
  const startProduct = Product.fromId(start);
  const endProduct = Product.fromId(end);
  return mapper().getHalfHourProductsBetweenTimes(
    timeGetter().getCalendarTime(startProduct).start,
    timeGetter().getCalendarTime(endProduct).end,
  ).length;
}, countPeriodsInRangeMemoResolver);

const calculatePricing = (
  pricing: Pricing,
  valueToCalculate: keyof Pricing,
  numberOfPeriods: number,
): Pricing => {
  if (valueToCalculate === 'price') {
    if (pricing.volume === null || pricing.totalRevenue === null) {
      return pricing;
    }

    return {
      ...pricing,
      price: parseFloat(
        (
          pricing.totalRevenue /
          (pricing.volume * (numberOfPeriods / 2))
        ).toFixed(2),
      ),
    };
  }

  if (valueToCalculate === 'totalRevenue') {
    if (pricing.price === null || pricing.volume === null) {
      return pricing;
    }

    return {
      ...pricing,
      totalRevenue: parseFloat(
        (pricing.price * (pricing.volume * (numberOfPeriods / 2))).toFixed(2),
      ),
    };
  }

  return pricing;
};

const addRangeToExistingState = (
  state: BalancingReserveState,
  payload: AddRangeActionPayload,
): Units => {
  const id = uniqueId();

  const productId1 = new Product(
    ProductType.HalfHour,
    '1',
    CalendarDate.fromFormat(state.date),
  ).toId();
  const productId6 = new Product(
    ProductType.HalfHour,
    '6',
    CalendarDate.fromFormat(state.date),
  ).toId();

  return {
    ...state.units,
    [payload.unitId]: {
      ...state.units[payload.unitId],
      spRanges: {
        ...state.units[payload.unitId].spRanges,
        [id]: {
          id,
          start: productId1,
          end: productId6,
          type: 'POSITIVE',
          price: null,
          volume: 192,
          totalRevenue: null,
        },
      },
    },
  };
};

const roundNewRangeData = (newRangeData: Partial<SpRange>): Partial<SpRange> =>
  Object.entries(newRangeData).reduce((acc, [key, value]) => {
    if (key === 'price' || key === 'totalRevenue') {
      return {
        ...acc,
        [key]: value === null ? null : parseFloat((value as number).toFixed(2)),
      };
    }
    return { ...acc, [key]: value };
  }, {});

const updateRangeData = (
  previous: SpRange,
  incomingRangeData: Partial<SpRange>,
): SpRange => {
  const newRangeData = roundNewRangeData(incomingRangeData);
  const mergedData = { ...previous, ...newRangeData };
  const numberOfPeriods = countPeriodsInRange(mergedData.start, mergedData.end);
  if (
    newRangeData.price !== undefined &&
    newRangeData.price !== previous.price
  ) {
    // We're updating the price, so we need to recalculate the total revenue
    return {
      ...previous,
      ...newRangeData,
      ...calculatePricing(
        {
          volume: previous.volume,
          totalRevenue: previous.totalRevenue,
          price: newRangeData.price,
        },
        'totalRevenue',
        numberOfPeriods,
      ),
    };
  }
  if (
    newRangeData.volume !== undefined &&
    newRangeData.volume !== previous.volume
  ) {
    // We're updating the volume, so we need to recalculate the price
    return {
      ...previous,
      ...newRangeData,
      ...calculatePricing(
        {
          volume: newRangeData.volume,
          totalRevenue: previous.totalRevenue,
          price: previous.price,
        },
        'price',
        numberOfPeriods,
      ),
    };
  }
  if (
    (newRangeData.start !== undefined &&
      newRangeData.start !== previous.start) ||
    (newRangeData.end !== undefined && newRangeData.end !== previous.end)
  ) {
    // We're updating the start or end, which affects the volume calculation, so we need to recalculate the price
    return {
      ...previous,
      ...newRangeData,
      ...calculatePricing(
        {
          volume: previous.volume,
          totalRevenue: previous.totalRevenue,
          price: previous.price,
        },
        'price',
        numberOfPeriods,
      ),
    };
  }
  if (
    newRangeData.totalRevenue !== undefined &&
    newRangeData.totalRevenue !== previous.totalRevenue
  ) {
    // We're updating the total revenue, so we need to recalculate the price
    return {
      ...previous,
      ...newRangeData,
      ...calculatePricing(
        {
          volume: previous.volume,
          totalRevenue: newRangeData.totalRevenue,
          price: previous.price,
        },
        'price',
        numberOfPeriods,
      ),
    };
  }
  return mergedData;
};

const editRangeInExistingState = (
  state: BalancingReserveState,
  payload: EditRangeActionPayload,
): Units => ({
  ...state.units,
  [payload.unitId]: {
    ...state.units[payload.unitId],
    spRanges: {
      ...state.units[payload.unitId].spRanges,
      [payload.rangeId]: {
        ...state.units[payload.unitId].spRanges[payload.rangeId],
        ...updateRangeData(
          state.units[payload.unitId].spRanges[payload.rangeId],
          payload.newRangeData,
        ),
      },
    },
  },
});

const removeRangeFromExistingState = (
  state: BalancingReserveState,
  payload: RemoveRangeActionPayload,
): Units => ({
  ...state.units,
  [payload.unitId]: {
    ...state.units[payload.unitId],
    spRanges: omit(state.units[payload.unitId].spRanges, payload.rangeId),
  },
});

const updateBmuInExistingState = (
  state: BalancingReserveState,
  payload: UpdateBmuActionPayload,
): Units =>
  mapUnits(state.units, (unit) => {
    if (unit.id === payload.unitId) {
      return { ...unit, bmu: payload.newBmu };
    }
    if (unit.bmu === payload.newBmu) {
      return { ...unit, bmu: null };
    }
    return unit;
  });

const convertPeriodDate = (
  period: string,
  oldDate: string,
  newDate: string,
): string => {
  const product = Product.fromId(period);
  const previousToNewDate = CalendarDate.fromFormat(newDate)
    .minus(1)
    .toFormat();

  const newCalendarDate = CalendarDate.fromFormat(
    product.getCalendarDate().toFormat() === oldDate
      ? newDate
      : previousToNewDate,
  );

  return new Product(
    product.getType(),
    product.getName(),
    newCalendarDate,
  ).toId();
};

const updateDateInExistingState = (
  state: BalancingReserveState,
  payload: UpdateDateActionPayload,
): BalancingReserveState => ({
  ...state,
  units: mapUnits(state.units, (unit) => ({
    ...unit,
    spRanges: mapSpRanges(unit.spRanges, (spRange) => ({
      ...spRange,
      start: convertPeriodDate(spRange.start, state.date, payload.newDate),
      end: convertPeriodDate(spRange.end, state.date, payload.newDate),
    })),
  })),
  date: payload.newDate,
});

export const forEachUnits = (units: Units, callback: (unit: Unit) => void) =>
  Object.values(units).forEach(callback);

export const mapUnits = (units: Units, mapper: (unit: Unit) => Unit): Units =>
  Object.values(units).reduce(
    (acc: Units, unit: Unit): Units => ({
      ...acc,
      [unit.id]: mapper(unit),
    }),
    {} as Units,
  );

export const mapSpRanges = (
  spRanges: SpRanges,
  mapper: (spRange: SpRange) => SpRange,
): SpRanges =>
  Object.values(spRanges).reduce(
    (acc: SpRanges, spRange: SpRange): SpRanges => ({
      ...acc,
      [spRange.id]: mapper(spRange),
    }),
    {} as SpRanges,
  );

export const balancingReserveSlice = createSlice({
  name: 'balancingReserveState',
  initialState,
  reducers: {
    addRange: (state, action: PayloadAction<AddRangeActionPayload>) => {
      state.units = addRangeToExistingState(state, action.payload);
    },
    editRange: (state, action: PayloadAction<EditRangeActionPayload>) => {
      state.units = editRangeInExistingState(state, action.payload);
    },
    removeRange: (state, action: PayloadAction<RemoveRangeActionPayload>) => {
      state.units = removeRangeFromExistingState(state, action.payload);
    },
    updateBmu: (state, action: PayloadAction<UpdateBmuActionPayload>) => {
      state.units = updateBmuInExistingState(state, action.payload);
    },
    updateDate: (state, action: PayloadAction<UpdateDateActionPayload>) =>
      updateDateInExistingState(state, action.payload),
    setAsSubmitting: (state) => {
      state.isSubmitting = true;
    },
    submissionSuccess: (state) => {
      state.isSubmitting = false;
    },
    submissionFailure: (state) => {
      state.isSubmitting = false;
    },
  },
});

export const {
  addRange,
  editRange,
  removeRange,
  updateBmu,
  updateDate,
  setAsSubmitting,
  submissionSuccess,
  submissionFailure,
} = balancingReserveSlice.actions;

export default balancingReserveSlice.reducer;
