import React from 'react';
import { useOutputSelector } from '../../store/selectors/outputSelector';
import { Table } from './styles';

const Output = () => {
  const output = useOutputSelector();

  if (!output.length) {
    return null;
  }

  return (
    <Table>
      <thead>
        <tr>
          <th>BMU</th>
          <th>Basket family</th>
          <th>Auction Date</th>
          <th>SP</th>
          <th>PBR</th>
          <th>NBR</th>
          <th>Price</th>
        </tr>
      </thead>
      <tbody>
        {output.map((row) => (
          <tr key={row.id}>
            <td>{row.bmu}</td>
            <td>{row.basketFamily}</td>
            <td>{row.auctionDate.toFormat()}</td>
            <td>{row.sp}</td>
            <td>{row.type === 'POSITIVE' ? row.volume : ''}</td>
            <td>{row.type === 'NEGATIVE' ? row.volume : ''}</td>
            <td>{row.price.toFixed(2)}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default Output;
