import React from 'react';
import { EfaDate } from '@edfenergy/shift-desk-efa-calendar';
import { useDispatch } from 'react-redux';
import { updateDate } from '../../store/balancingReserveSlice';
import { FieldSpan } from '../SpRange/styles';

const BrDate = ({ efaDate }: { efaDate: EfaDate }) => {
  const dispatch = useDispatch();
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateDate({ newDate: event.target.value }));
  };
  return (
    <p>
      <FieldSpan>
        Delivery date:
        <input
          aria-label="Date"
          type="date"
          value={efaDate.toFormat()}
          onChange={handleOnChange}
        />
      </FieldSpan>
    </p>
  );
};

export default BrDate;
